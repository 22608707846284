<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="headline">
        <h3>¿Desea crear un nuevo entrenamiento?</h3>
        <small>Esto generará un nuevo entrenamiento en la base de datos.</small>
      </v-card-title>
      <v-card-actions>
        <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
        >
          {{ $t('app.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary dark"
          @click="open"
        >
          {{ $t('app.buttons.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'WeekAddAlert',
    data() {
      return {
        dialog: false,
        week: {}
      }
    },
    mounted () {
      EventBus.$on('trainings-add-alert', () => {
        this.dialog = true
      })
    },
    methods: {
      async open () {
        this.dialog = false
        this.toggleLoader()

        await this.$http.post(route("trainings"), {
          data: {
            points: 1
          }
        })
          .then(async response => {
            if (response.body.data) {
              let training = response.body.data
              EventBus.$emit('reload-items')
              EventBus.$emit('trainings-edit', training)
            } else {
              this.processError(response)
            }
          }, error => {
            this.processError(error)
          })

        this.toggleLoader()
      },
    },
  }
</script>

<style scoped>

</style>
